$('.info_slider_section .block').slick({
  dots: true,
  infinite: true,
  speed: 300,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><i class="fa fa-arrow-left"></i></div>',
  nextArrow: '<div class="next"><i class="fa fa-arrow-right"></i></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

$('.info_block_slider .block_sld').slick({
  dots: true,
  infinite: true,
  speed: 300,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><i class="fa fa-arrow-left"></i></div>',
  nextArrow: '<div class="next"><i class="fa fa-arrow-right"></i></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    }
  ]
});

$('.info_certificates .block_certifi').slick({
  dots: true,
  infinite: true,
  speed: 300,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><i class="fa fa-arrow-left"></i></div>',
  nextArrow: '<div class="next"><i class="fa fa-arrow-right"></i></div>',
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
		arrows: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
		arrows: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    }
  ]
});

$('.info_partners .block_partners').slick({
  dots: false,
  infinite: true,
  speed: 300,
  autoplay: false,
  slidesToShow: 7,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><i class="fa fa-arrow-left"></i></div>',
  nextArrow: '<div class="next"><i class="fa fa-arrow-right"></i></div>',
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
		arrows: false,
		dots: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
		arrows: false,
		dots: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
		arrows: false,
		dots: false
      }
    }
  ]
});

$(function(){ 
	$(".navigation > .nav a").on("click", function(e){
        
        var href = $(this).attr("href"), anchor = $(href);
       
       $("html, body").animate({
          scrollTop: anchor.offset().top -130
       }, 1500);
    });
	
	var buttons = $(".buttons");
	fimenu();
	
    function fimenu(e){
		var scrolltop = $(this).scrollTop();
		
		if(scrolltop > 350){
			buttons.slideDown();
			$(".navigation_container").addClass("navigation_height");
			
		}else{
			buttons.slideUp();
			$(".navigation_container").removeClass("navigation_height");
		}
	}
	
	$(document).on("scroll", fimenu);
	
	buttons.on("click", function(){
		$("html, body").animate({
			scrollTop: 0
		}, 2000);
	});
	
	$(document).on("scroll", fimenu);
	
	var burgerMenu = $(".navbar-header .menu-trigger");
	var content = $(".burger-menu");
	
	burgerMenu.on("click", function(e){
		e.preventDefault();
		burgerMenu.toggleClass("active");
		content.slideToggle();
	});
	
	var dropfirst = $(".block_cat_mob .drop_first");
	var dropsecond = $(".block_cat_mob .drop_second");
	var dropthird = $(".block_cat_mob .drop_third");
	
	dropfirst.on("click", function(e){
		$(".block_cat_mob .open_first").slideToggle();
	});
	dropsecond.on("click", function(e){
		$(".block_cat_mob .open_second").slideToggle();
	});
	dropthird.on("click", function(e){
		$(".block_cat_mob .open_third").slideToggle();
	});
	
	$(".basket_open .product-remove .remove").on("click", function(){
		$(this).remove(".goods");
	});
	
	jQuery.validator.addMethod("checkMask", function(value, element) {
		return /\+\d{1}\(\d{3}\)\d{3}-\d{4}/g.test(value); 
	});

	$('.contact-section form').validate();
	$('#form_modal form').validate();

	$.validator.addClassRules({
		'js-phone': {
			checkMask: true
		}
	});

	$('.js-phone').mask("+7(999)999-9999", {autoclear: false});
});

$(document).ready(function(){
	$("#face").on('change', function() {
		if ($(this).find(':selected').val() === '0') {
		$(".first_block").show();
		$(".p_legal").removeClass("active");
	} else {
		$(".full_name").hide();
		$(".p_legal").addClass("active");;
	}
	});
});

$("[#form_modal_registration]").fancybox({
	speed : 330,
	infobar : true,
	buttons : false,
	protect : true,
});

/*$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});*/

$(document).ready(function() {
    $('.goods').on('click', function(evt) {
        var elem = evt.target;
        var container = evt.currentTarget;
        var input = container.getElementsByClassName('counter')[0];
        var sum = container.getElementsByClassName('sum')[0];
        var count = parseInt(input.getAttribute('data-count'), 10);
        var price = parseInt(input.getAttribute('data-price'), 10);
        
    	if (elem.classList.contains('minus')) {
        	count = count == 1 ? count : (count - 1);
        } else if (elem.classList.contains('plus')){
        	count += 1;
        }

        input.value = count;
        sum.innerHTML = price * count;
        input.setAttribute('data-count', count);
    });
});

$('.site_btn').click( function() {

	var fruitid = $(this).attr("data-id");
	var fruitsum = $(this).attr("data-price");

	$.ajax({
	  dataType: 'json',
	  data: 'paramid=' + fruitid + "&paramsum=" + fruitsum,
	  url: 'server.php?add=1',
	  success: function(jsondata){
		$('.basket .col').html(jsondata.num);
		$('.basket .rub').html(jsondata.sum);
	  }
	});
});

$(document).ready(function (){
    //улетает картинка
    $(".site_btn").on("click",function(){
        id = $(this).attr("for");
        var_left = $(".basket").offset().left;
        var_top = $(".basket").offset().top;

        $("#product"+id)
			.clone()
			.css({'position' : 'absolute', 'z-index' : '1000','width':'100px','height':'100px', top: $(this).offset().top-300, left:$(this).offset().left-20})
			.appendTo("body")
			.animate({opacity: 0.05,
				left: $(".basket").offset()['left'],
				top: $(".basket").offset()['top'],
				width: 20}, 1000, function() {
				$(this).remove();
		});
    });
});

function printDiv(divName) {
     var printContents = document.getElementById(divName).innerHTML;
     var originalContents = document.body.innerHTML;

     document.body.innerHTML = printContents;

     window.print();

     document.body.innerHTML = originalContents;
}

$(function(){
	var context = $(this);
	context.section = $(".tab-posts-box > .nav > li");
	context.textSection = $(".tab-posts-box > .tab-content > .tab-pane");
	
	context.section.on("click", function(){
		context.section.removeClass("active").eq($(this).index()).addClass("active");
		context.textSection.hide().eq($(this).index()).show();
	}).eq(0).addClass("active");
	
	$(function(){
		context.textSection.eq(0).show();
	});
});

$(document).ready(function(){
	$("#delivery_method").on('change', function() {
		if ($(this).find(':selected').val() === '2') {
		$(".cities").show();
	} else {
		$(".cities").hide();
		$(".shipping_cost").hide();
		$(".free_shipping").hide();
		$(".shipping_specify").hide();
	}
	});
});

$(document).ready(function(){
	var amount = $(".totals .amount").html(); 

	$("#city").on('change', function() {
		if ($(this).find(':selected').val() === '2') {
			if (amount < "2500"){
				$(".shipping_specify").hide();
				$(".free_shipping").show();
			} else{
				$(".shipping_specify").hide();
				$(".shipping_cost").show();
			}
	} else {
		$(".shipping_cost").hide();
		$(".free_shipping").hide();
		$(".shipping_specify").show();
	}
	});
});